import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FFFDF8',
      neutralLight3: '#F7EFE5',
      neutralLight2: '#DDD5CA',
      neutralLight1: '#ACA59C',
      neutralDark3: '#363636',
      neutralDark2: '#242424',
      neutralDark1: '#000000',
      primaryDark: '#B5892F',
      primaryLight: '#DBA73D',
      danger: '#CC5858',
    },
  },
  fontFamily: {
    heading: "'Jost', sans-serif",
    paragraph: "'Manrope', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
